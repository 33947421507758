// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}
// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

//   .workpackageRows {
//       border-top: 1px solid lightgray;
//   }

// styling for pdf document in unpaid leave request
.react-pdf__Document {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}
.react-pdf__Page__textContent {
  display: none;
}

// removing padding from tabs component in administration settings -> permissions
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 17px;
}

// privileges column headers
.privilege-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 44px;
}
.privilege-options {
  right: 0;
  position: absolute;
}
.privilege-title {
  margin: 0 auto;
  text-align: center;
}

.ant-transfer-list-content-item-remove {
  visibility: hidden;
}

.ant-modal-close {
  visibility: hidden;
}

.employee-birth-date-fitler .ant-picker-year-btn {
  display: none;
}

.team-pending-timesheets .highlighted-timesheet {
  background-color: rgb(255,243,202) !important;
}

.team-pending-timesheets .highlighted-timesheet .ant-table-cell-row-hover {
  background-color: rgb(255,243,202) !important;
}

.custom-notification-list-item:hover {
  background-color: #d9d9d9;
  cursor: pointer;
}

.notification-list-items .ant-list-item:hover {
    background-color: #d9d9d9;
    cursor: pointer;
}

.notification-list-items .ant-list-item-meta-title {
  margin: 0;
}

button.refresh-pulse-button:not(:disabled) {
  box-shadow: 0 0 0 0 #1890ff;
	transform: scale(1);
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #9bceff;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px #badaf8;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #fbfcfd;
	}
}
.budget-editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.budget-editable-cell-value-wrap:hover {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
